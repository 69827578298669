.pharmacy-confirmation-details {
    margin-top: 20px;
  }
  
  .pharmacy-confirmation-details h3 {
    margin-bottom: 10px;
  }
  
  .pharmacy-confirmation-details table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .pharmacy-confirmation-details th,
  .pharmacy-confirmation-details td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .pharmacy-confirmation-details th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .pharmacy-confirmation-details .available {
    background-color: #e6ffe6;
  }
  
  .pharmacy-confirmation-details .not-available {
    background-color: #fff0f0;
  }
  
  .pharmacy-confirmation-details .grand-total {
    margin-top: 10px;
    text-align: right;
    font-size: 1.1em;
  }