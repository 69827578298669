.active-orders-container {
  display: flex;
  height: calc(100vh - 60px);
}

.orders-list-panel {
  width: 300px;
  overflow-y: auto;
}

.order-details-panel {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto auto auto;
  gap: 20px;
}

.order-progress {
  grid-column: 1;
  grid-row: 1 / span 3;
}

.main-details {
  grid-column: 2;
  grid-row: 1;
}

.medicine-list {
  grid-column: 2;
  grid-row: 2;
}

.pharmacy-confirmation {
  grid-column: 1;
  grid-row: 3;
}

h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.medicine-bill {
  margin-top: 20px;
}

.bill-preview {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
}

.bill-preview pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.bill-preview button {
  margin-top: 10px;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.bg-white {
  background-color: white;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.bg-gray-200 {
  background-color: #e2e8f0;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.space-x-2 > * + * {
  margin-left: 0.5rem;
}

.text-xl {
  font-size: 1.25rem;
}

.font-bold {
  font-weight: bold;
}

.text-white {
  color: white;
}

.bg-blue-500 {
  background-color: #4299e1;
}

.bg-blue-600:hover {
  background-color: #3182ce;
}

.bg-red-500 {
  background-color: #f56565;
}

.bg-red-600:hover {
  background-color: #e53e3e;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.rounded {
  border-radius: 0.25rem;
}

.flex-grow {
  flex-grow: 1;
}

.overflow-hidden {
  overflow: hidden;
}

.relative {
  position: relative;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.object-contain {
  object-fit: contain;
}

.max-w-full {
  max-width: 100%;
}

.max-h-full {
  max-height: 100%;
}

.absolute {
  position: absolute;
}

.top-2 {
  top: 0.5rem;
}

.left-2 {
  left: 0.5rem;
}

.z-10 {
  z-index: 10;
}

.mt-4 {
  margin-top: 30px;
}
