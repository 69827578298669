.active-orders-list {
    background-color: #1a73e8;
    color: white;
    padding: 20px;
    height: 100%;
    overflow-y: auto;
  }
  
  .active-orders-list h2 {
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  .active-orders-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .active-orders-list li {
    background-color: rgba(255, 255, 255, 0.1);
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .active-orders-list li:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .active-orders-list li.selected {
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  .active-orders-list li div {
    margin-bottom: 5px;
  }