/* OrderStatus.css */

.order-status-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  margin: auto;
}

.order-status-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.order-status-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.order-status-table {
  width: 100%;
  border-collapse: collapse;
}

.order-status-table thead {
  background-color: #f7f7f7;
}

.order-status-table th,
.order-status-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.order-status-table th {
  font-weight: bold;
  font-size: 16px;
}

.order-status-table td {
  font-size: 14px;
}

.order-status-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.order-status-button {
  padding: 5px 15px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-yes {
  background-color: #4caf50;
  color: white;
}

.button-no {
  background-color: #f44336;
  color: white;
}

.button-upload {
  background-color: #2196f3;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-upload:hover,
.button-yes:hover,
.button-no:hover {
  opacity: 0.8;
}

.hidden-input {
  display: none;
}
