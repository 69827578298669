.order-progress {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    height: 100%;
    overflow-y: auto;
  }
  
  .progress-step {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
  }
  
  .progress-step:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 30px;
    left: 12px;
    width: 2px;
    height: calc(100% + 10px);
    background-color: #e0e0e0;
  }
  
  .step-indicator {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #e0e0e0;
    margin-right: 15px;
    z-index: 1;
  }
  
  .step-label {
    font-size: 14px;
    color: #666;
  }
  
  .progress-step.active .step-indicator {
    background-color: #4caf50;
  }
  
  .progress-step.active .step-label {
    color: #333;
    font-weight: bold;
  }
  
  .progress-step.active:not(:last-child)::after {
    background-color: #4caf50;
  }
  
  .progress-step.current .step-indicator {
    border: 2px solid #4caf50;
    background-color: white;
  }
  
  .progress-step.current .step-label {
    color: #4caf50;
    font-weight: bold;
  }