.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
  }
  
  .modal-content h2 {
    margin-bottom: 20px;
    color: black 
 }
  
  .modal-content table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    color: black 

  }
  
  .modal-content th, .modal-content td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    color: black 

  }
  
  .modal-content th {
    background-color: #f2f2f2;
    color: black 

  }
  
  .modal-content input, .modal-content select {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    color: black 

  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-actions button {
    margin-left: 10px;
    padding: 8px 15px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .save-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .cancel-button {
    background-color: #f44336;
    color: white;
  }