.main-details table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: #f8f9fa;
  }
  
  .main-details th, .main-details td {
    border: 1px solid #dee2e6;
    padding: 12px;
    text-align: left;
  }
  
  .main-details th {
    background-color: #007bff;
    color: white;
    font-weight: 600;
  }
  
  .main-details tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .order-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .action-button {
    margin-right: 5px;
    padding: 6px 12px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    font-weight: 600;
    transition: background-color 0.3s;
  }
  
  .action-button.expand {
    background-color: #28a745;
    color: white;
  }
  
  .action-button.edit {
    background-color: #ffc107;
    color: #212529;
  }
  
  .action-button:hover {
    opacity: 0.8;
  }
  
  .expanded-details {
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .details-section {
    background-color: #f8f9fa;
    border-radius: 4px;
    padding: 15px;
  }
  
  .details-section h4 {
    margin-top: 0;
    margin-bottom: 15px;
    color: #007bff;
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
  }
  
  .detail-row {
    display: flex;
    margin-bottom: 10px;
  }
  
  .detail-label {
    flex: 1;
    font-weight: 600;
    color: #495057;
  }
  
  .detail-value {
    flex: 2;
    color: #212529;
    word-break: break-word;
  }